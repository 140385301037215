body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modalButton {
  background-color: #25658f !important;
  color: white !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}


.fc {
  /* the calendar root */
  max-width: 1250px;
  margin: 0 auto;
  --fc-border-color: #25658f;
  --fc-button-bg-color: #25658f;
  --fc-button-active-bg-color: white;
  --fc-today-bg-color: rgba(115, 139, 156, 0.18);
}

.fc a {
  color: #25658f;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  color: black;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

/* .nav-link.active,
.nav-item.show .nav-link {
  color: white !important;
  background-color: #25658f !important;
} */